<script lang="ts" setup>
import type { TRegionCode } from "~/lib/TRegionCode";

const { countryCode, locale } = defineProps({
    name: { type: String, default: "" },
    countryCode: { type: String as PropType<TRegionCode>, default: "" },
    rating: { type: Number, default: 0 },
    title: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    text: { type: String, default: "" },
    locale: { type: String, default: "en-US" },
    showActionButton: Boolean,
    showFlag: Boolean,
    reverse: Boolean,
    block: Boolean,
    inDialog: Boolean,
    showDecorationQuote: Boolean
});

const getRegionName = new Intl.DisplayNames([locale], { type: "region" });
const regionName = computed(() => (countryCode ? getRegionName.of(countryCode.toUpperCase()) : ""));
const isDialogShow = ref(false);
</script>
<template>
    <VHover>
        <template #default="{ isHovering, props }">
            <VToolbar v-if="inDialog" height="auto" floating class="toolbar">
                <VRow no-gutters align="center" class="mt-4 mx-2 card px-2 pt-1 px-sm-4 pt-sm-3 px-md-5 pt-md-4 pb-0 mb-0">
                    <VCol v-if="countryCode && showFlag" cols="auto" class="mr-2">
                        <Flag :region-code="countryCode" size="2rem" />
                    </VCol>
                    <VCol cols>
                        <div class="text-body-1 text-md-h6 font-weight-medium text-red">{{ name }}</div>
                        <div class="text-caption text-sm-body-2 text-md-body-1 mt-n2">{{ regionName }}</div>
                    </VCol>
                    <VCol v-if="rating" cols="12">
                        <VIcon v-for="n in rating" :key="n" icon="$star" size="medium" color="yellow-darken-3" />
                    </VCol>
                </VRow>
            </VToolbar>
            <VCard
                v-bind="props"
                :class="['my-4 mx-2 card px-2 py-1 px-sm-4 py-sm-3 px-md-5 py-md-4', { 'on-hover': isHovering, 'full-width': block, 'in-dialog': inDialog }]"
                :elevation="inDialog ? 1 : isHovering ? 2 : 8"
                :color="inDialog ? 'white' : isHovering ? 'red-light' : 'blue-light'"
                height="auto"
            >
                <VRow no-gutters :class="{ 'flex-reverse': reverse }">
                    <VCol cols="12">
                        <VCardTitle class="text-medica pb-0">{{ title }}</VCardTitle>
                        <VCardSubtitle v-if="subTitle">{{ subTitle }}</VCardSubtitle>
                        <VCardText class="text-caption text-md-body-2 text-lg-body-1 pt-2" :class="{ 'mt-5': !subTitle }">
                            <div class="card-text" v-html="text"></div>
                        </VCardText>
                    </VCol>
                    <VCol cols="12"><VDivider /></VCol>
                    <VCol cols="12">
                        <VCardText v-if="!inDialog" class="test">
                            <VRow no-gutters>
                                <VCol cols>
                                    <VRow no-gutters align="center">
                                        <VCol v-if="countryCode && showFlag" cols="auto" class="mr-2">
                                            <Flag :region-code="countryCode" size="2rem" />
                                        </VCol>
                                        <VCol cols>
                                            <div class="text-body-1 text-md-h6 font-weight-medium text-red">{{ name }}</div>
                                            <div class="text-caption text-sm-body-2 text-md-body-1 mt-n2">{{ regionName }}</div>
                                        </VCol>
                                    </VRow>
                                    <div v-if="rating">
                                        <VIcon v-for="n in rating" :key="n" icon="$star" size="medium" color="yellow-darken-3" />
                                    </div>
                                </VCol>
                                <VCol v-if="showDecorationQuote" cols="auto">
                                    <Svg name="quote" width="3.8rem" style="opacity: 0.35"></Svg>
                                </VCol>
                            </VRow>
                        </VCardText>
                        <VBtn v-if="showActionButton" variant="text" class="read-more-btn text-medica text-none" @click="isDialogShow = !isDialogShow">
                            Read more
                        </VBtn>
                    </VCol>
                </VRow>
            </VCard>
        </template>
    </VHover>
    <ReviewsDialog
        v-if="!inDialog"
        v-model="isDialogShow"
        :name="name"
        :country-code="countryCode"
        :rating="rating"
        :title="title"
        :sub-title="subTitle"
        :text="text"
        show-flag
        reverse
        :locale="locale"
    ></ReviewsDialog>
</template>
<style lang="scss" scoped>
.toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: inherit;
}

.card {
    width: 15.7rem;
    position: relative;

    &:not(.in-dialog) .card-text {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 3) !important;
        -webkit-box-orient: vertical;
        word-break: var(--word-break, "none");
        overflow: hidden !important;
        hyphens: auto;
    }
    .test {
        position: sticky !important;
        top: 0 !important;
    }

    .read-more-btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .flex-reverse {
        flex-direction: column-reverse;
    }
    @media screen and (min-width: 600px) {
        width: 17.5rem;
    }

    @media screen and (min-width: 960px) {
        width: 24rem;
    }

    @media screen and (min-width: 1280px) {
        width: 26.5rem;
    }

    &.full-width,
    &.in-dialog {
        width: 100% !important;
    }
    &.in-dialog {
        margin: 0 !important;
    }
}
</style>
