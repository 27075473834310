<script setup lang="ts">
import type { PropType } from "vue";
import Austria from "../assets/svg/flags/austria.svg";
import Bulgaria from "../assets/svg/flags/bulgaria.svg";
import Czechia from "../assets/svg/flags/czechia.svg";
import Denmark from "../assets/svg/flags/denmark.svg";
import Estonia from "../assets/svg/flags/estonia.svg";
import Finland from "../assets/svg/flags/finland.svg";
import Germany from "../assets/svg/flags/germany.svg";
import Ireland from "../assets/svg/flags/ireland.svg";
import Lithuania from "../assets/svg/flags/lithuania.svg";
import Norway from "../assets/svg/flags/norway.svg";
import Poland from "../assets/svg/flags/poland.svg";
import Scotland from "../assets/svg/flags/scotland.svg";
import Slovakia from "../assets/svg/flags/slovakia.svg";
import Spain from "../assets/svg/flags/spain.svg";
import Sweden from "../assets/svg/flags/sweden.svg";
import Ukraine from "../assets/svg/flags/ukraine.svg";
import UnitedKingdom from "../assets/svg/flags/united-kingdom.svg";
import UnitedStates from "../assets/svg/flags/united-states.svg";
import type { TRegionCode } from "~/lib/TRegionCode";

const { regionCode, size } = defineProps({
    regionCode: {
        type: String as PropType<TRegionCode>,
        required: true
    },
    size: {
        type: String,
        default: "1rem"
    }
});

const styles = { height: size, width: size, verticalAlign: "middle" };
</script>

<template>
    <div v-if="!regionCode" role="icon"></div>
    <Austria v-else-if="regionCode === 'AT'" :style="styles" />
    <Bulgaria v-else-if="regionCode === 'BG'" :style="styles" />
    <Czechia v-else-if="regionCode === 'CZ'" :style="styles" />
    <Denmark v-else-if="regionCode === 'DK'" :style="styles" />
    <Estonia v-else-if="regionCode === 'EE'" :style="styles" />
    <Finland v-else-if="regionCode === 'FI'" :style="styles" />
    <Germany v-else-if="regionCode === 'DE'" :style="styles" />
    <Ireland v-else-if="regionCode === 'IE'" :style="styles" />
    <Lithuania v-else-if="regionCode === 'LT'" :style="styles" />
    <Norway v-else-if="regionCode === 'ND'" :style="styles" />
    <Poland v-else-if="regionCode === 'PL'" :style="styles" />
    <Scotland v-else-if="regionCode === 'GB-SCT'" :style="styles" />
    <Slovakia v-else-if="regionCode === 'SK'" :style="styles" />
    <Spain v-else-if="regionCode === 'ES'" :style="styles" />
    <Sweden v-else-if="regionCode === 'SE'" :style="styles" />
    <Ukraine v-else-if="regionCode === 'UA'" :style="styles" />
    <UnitedKingdom v-else-if="['UK', 'GB'].includes(regionCode)" :style="styles" />
    <UnitedStates v-else-if="regionCode === 'US'" :style="styles" />
</template>
