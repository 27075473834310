<script lang="ts" setup>
import { useDisplay } from "vuetify";
import type { PropType } from "vue";
import type { IReview } from "~/lib/review";

defineProps({
    disableSlider: Boolean,
    hideWatermark: Boolean,
    reviews: {
        type: Array as PropType<IReview[]>,
        required: true
    }
});

const { smAndUp } = useDisplay();
</script>
<template>
    <SectionWrapper :gradient-background="!disableSlider" :watermark-text="hideWatermark ? undefined : 'reviews'" full-width style="overflow: visible">
        <div class="wrapper">
            <h2 id="reviews" class="text-center">
                <DecoratedTitle
                    text="What patients say about our clinic"
                    decorated-phrase="What patients say"
                    append-icon="heart"
                    append-icon-size=".6em"
                    append-icon-position="120% -70%"
                    append-icon-animation-class="heart-beat"
                    prepend-icon="decorator-3"
                    prepend-icon-size="1em"
                    prepend-icon-position="-100% 100%"
                    prepend-icon-animation-class="shake"
                    prepend-icon-animation-origin="top right"
                    class="text-center"
                />
            </h2>
        </div>
        <ClientOnly>
            <VSlideGroup v-if="!disableSlider" class="mt-4 mt-sm-5 mt-md-10 mt-lg-15" mobile-breakpoint="sm" tabindex="0" :show-arrows="smAndUp">
                <VSlideGroupItem v-for="(review, i) in reviews" :key="i">
                    <ReviewsCard
                        :name="review.name"
                        :country-code="review.countryCode"
                        :rating="review.rating"
                        :title="review.title"
                        :sub-title="review.subTitle"
                        :text="review.text"
                        show-action-button
                        show-decoration-quote
                        show-flag
                    ></ReviewsCard>
                </VSlideGroupItem>
            </VSlideGroup>
            <div v-else class="mt-4 mt-sm-5 mt-md-10 mt-lg-15">
                <ReviewsCard
                    v-for="(review, i) in reviews"
                    :key="i"
                    :name="review.name"
                    :country-code="review.countryCode"
                    :rating="review.rating"
                    :title="review.title"
                    :sub-title="review.subTitle"
                    :text="review.text"
                    show-action-button
                    show-decoration-quote
                    show-flag
                    block
                    reverse
                ></ReviewsCard>
            </div>
        </ClientOnly>
    </SectionWrapper>
</template>
<style lang="scss" scoped>
:deep(div.v-slide-group__content) {
    justify-content: center !important;
}

:deep(.wrapper) {
    &::before {
        font-size: clamp(8rem, 15vw, 15rem);
    }
}
</style>
